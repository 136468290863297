import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./css/1/navigation.css";

export default function Navigation() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Settings //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [viewUserName, getUserName] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        getUserName(response.data.user[0].userName);
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [appName, getAppName] = useState("");
  const [baseUrl, getBaseUrl] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/getSettingsCache`).then((response) => {
      getAppName(response.data[0].appName)
      getBaseUrl(response.data[0].appBaseUrl)
    })
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Logout   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const logout = () => {
    Axios.get(`${apiUrl}/api/logout`)
      .then((response) => {
        if (response.status === 200) {
          alert("Wylogowano użytkownika: " + viewUserName);
          window.location.reload(false);
        } else {
          console.error("Błąd podczas wylogowywania");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Navigation blurload">

      <span>
        <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
          <path className="svg-c2" opacity="1" d="M18 13h5v7h-5v-7z"></path>
          <path className="svg-c1" d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM2 4h18v5h2V4a2 2 0 0 0-2-2H2C.89 2 0 2.89 0 4v12a2 2 0 0 0 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4zm9 2l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03L11 6z"></path>
        </g></svg>
        <a key="home" href={baseUrl}>{appName}</a>
      </span>

      <ul>
        <li>Notice</li>
        <li>Admin</li>
        <li onClick={logout}>
          <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
            <path className="svg-c1" d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
          </g></svg>
        </li>
      </ul>

    </section>

  );

}