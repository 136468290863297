import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/gallery.css";

export default function Gallery() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login Status   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userID, viewUserID] = useState("");

  useEffect(() => {
    // View Login //
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        viewUserID(response.data.user[0].userID);
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Gallery   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [galleryName, setGalleryName] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");

  const submitGallery = async () => {
    Axios.post(`${apiUrl}/api/insertGallery`, {
      galleryName: galleryName,
      mediaUrl: mediaUrl,
    });
    alert("Dodano galerię.");
    fetchGalleryList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Gallery   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removeGallery = async (galleryid) => {
    Axios.delete(`${apiUrl}/api/deleteGallery/${galleryid}`);
    alert("Usunięto galerię.");
    fetchGalleryList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Media - Gallery   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [MediaReview, setMediaReview] = useState([]);
  const [GalleryReviewList, setGalleryReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchMediaList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getImage`);
    return response.data;
  };

  const fetchGalleryList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getGallery`);
    return response.data;
  };

  const { data: mediaReviewData, isLoading: isMediaReviewLoading } = useQuery(
    ["mediaList"],
    fetchMediaList
  );

  const { data: galleryReviewListData, isLoading: isGalleryReviewListLoading } = useQuery(
    ["galleryList"],
    fetchGalleryList
  );

  useEffect(() => {
    if (mediaReviewData) {
      setMediaReview(mediaReviewData);
    }
  }, [mediaReviewData]);

  useEffect(() => {
    if (galleryReviewListData) {
      setGalleryReviewList(galleryReviewListData);
    }
  }, [galleryReviewListData]);

  useEffect(() => {
    fetchGalleryList();
  }, []);

  if (isMediaReviewLoading || isGalleryReviewListLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Gallery blurload">

      <h2>Gallery</h2>

      <fieldset>
        {MediaReview
          .filter((val) => val.authorID === parseInt(userID) && val.mediaFor === "gallery")
          .map((val) => {
            return <input
              data-aos={"fade-left"}
              data-aos-duration={"800"}
              data-aos-offset={"50"}
              data-aos-delay={"400"}
              type="radio"
              name="baner"
              key={val.id}
              value={val.mediaName}
              style={{ backgroundImage: `url(${val.thumbnail})` }}
              onChange={(e) => { setMediaUrl(e.target.value) }}
            />
          })}
      </fieldset>
      <input
        type="text"
        name="galleryName"
        placeholder="Gallery name:"
        onChange={(e) => {
          setGalleryName(e.target.value)
        }} />
      <button onClick={submitGallery}>Submit</button>

      <table>
        <thead><tr><th>Gallery</th></tr></thead>
        <tbody>
          {GalleryReviewList.map((val) => {
            return <tr data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} className="blurload" key={val.id}>
              <td width="15%" className="tdThumb"><img alt={val.mediaName} src={`${apiUrl}/uploads/images/thumbnail/${val.mediaUrl}`} /></td>
              <td><p>{val.galleryName}</p></td>
              <td width="15%">
                <button
                  onClick={() => {
                    removeGallery(val.id);
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>

    </section >

  );

}