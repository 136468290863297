import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/category.css";

export default function Category() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login Status   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userID, viewUserID] = useState("");

  useEffect(() => {
    // View Login //
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        viewUserID(response.data.user[0].userID);
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Category   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [categoryName, setCategoryName] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");

  const submitCategory = async () => {
    Axios.post(`${apiUrl}/api/insertCategory`, {
      categoryName: categoryName,
      mediaUrl: mediaUrl,
    });
    alert("Dodano kategorię.");
    fetchCategoryList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Category   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removeCategory = async (categoryid) => {
    Axios.delete(`${apiUrl}/api/deleteCategory/${categoryid}`);
    alert("Usunięto kategorię.");
    fetchCategoryList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Category - Media  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [CategoryReviewList, setCategoryReviewList] = useState([]);
  const [MediaReview, setMediaReview] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchCategoryList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getCategoryList`);
    return response.data;
  };

  const fetchMediaList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getImage`);
    return response.data;
  };

  const { data: categoryReviewListData, isLoading: isCategoryReviewListLoading } = useQuery(
    ["categoryList"],
    fetchCategoryList
  );

  const { data: mediaReviewData, isLoading: isMediaReviewLoading } = useQuery(
    ["mediaList"],
    fetchMediaList
  );

  useEffect(() => {
    if (categoryReviewListData) {
      setCategoryReviewList(categoryReviewListData);
    }
  }, [categoryReviewListData]);

  useEffect(() => {
    if (mediaReviewData) {
      setMediaReview(mediaReviewData);
    }
  }, [mediaReviewData]);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  if (isCategoryReviewListLoading || isMediaReviewLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Category blurload">

      <h2>Category</h2>

      <input
        type="text"
        name="categoryName"
        placeholder="Category name:"
        onChange={(e) => {
          setCategoryName(e.target.value)
        }} />
      <fieldset>
        {MediaReview
          .filter((val) => val.authorID === parseInt(userID) && val.mediaFor === "category")
          .map((val) => {
            return <input
              data-aos={"fade-left"}
              data-aos-duration={"800"}
              data-aos-offset={"50"}
              data-aos-delay={"400"}
              type="radio"
              name="baner"
              key={val.id}
              value={val.mediaName}
              style={{ backgroundImage: `url(${val.thumbnail})` }}
              onChange={(e) => { setMediaUrl(e.target.value) }}
            />
          })}
      </fieldset>
      <button onClick={submitCategory}>Submit</button>

      <table>
        <thead><tr><th>Category</th></tr></thead>
        <tbody>
          {CategoryReviewList.map((val) => {
            return <tr data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} className="blurload" key={val.id}>
              <td width="15%" className="tdThumb"><img src={`${apiUrl}/uploads/images/thumbnail/${val.mediaUrl}`} /></td>
              <td><p>{val.categoryName}</p></td>
              <td width="15%">
                <button
                  onClick={() => {
                    removeCategory(val.id);
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>

    </section>

  );

}