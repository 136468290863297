import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/menutop.css";

export default function Menutop() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Menu Top   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [menuTopTitle, setMenuTopTitle] = useState("");
  const [menuTopLink, setMenuTopLink] = useState("");
  const [menuTopOrder, setMenuTopOrder] = useState("");

  const insertMenuTop = async () => {
    Axios.post(`${apiUrl}/api/insertMenuTop`, {
      menuTopTitle: menuTopTitle,
      menuTopLink: menuTopLink,
      menuTopOrder: menuTopOrder,
    });
    alert("Pozycja w menu została dodana.");
    fetchMenuTopList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Menu Top   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removeMenuTop = async (menuid) => {
    Axios.delete(`${apiUrl}/api/deleteMenuTop/${menuid}`);
    alert("Pozycja w menu została usunięta.");
    fetchMenuTopList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Menu Top //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [MenuTopReviewList, setMenuTopReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchMenuTopList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getMenuTop`);
    return response.data;
  };

  const { data: menuTopReviewData, isLoading: isMenuTopReviewLoading } = useQuery(
    ["menuTopList"],
    fetchMenuTopList
  );

  useEffect(() => {
    if (menuTopReviewData) {
      setMenuTopReviewList(menuTopReviewData);
    }
  }, [menuTopReviewData]);

  useEffect(() => {
    fetchMenuTopList();
  }, []);

  if (isMenuTopReviewLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Menutop blurload">

      <h2>Menutop</h2>

      <input
        type="text"
        name="menuTopTitle"
        placeholder="Menu name:" onChange={(e) => {
          setMenuTopTitle(e.target.value)
        }} />
      <input
        type="text"
        name="menuTopLink"
        placeholder="Link:" onChange={(e) => {
          setMenuTopLink(e.target.value)
        }} />
      <input
        type="number"
        name="menuTopOrder"
        placeholder="1" onChange={(e) => {
          setMenuTopOrder(e.target.value)
        }} />
      <button
        onClick={insertMenuTop}
      >
        Submit
      </button>

      <table>
        <thead><tr><th>Menu title</th><th>Link</th><th>Order</th><th width="50px"></th><th width="50px"></th></tr></thead>
        <tbody>
          {MenuTopReviewList.map((val) => {
            return <tr data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} className="fadeload" key={val.menuTopTitle}>
              <td><a href={val.menuTopLink}>{val.menuTopTitle}</a></td>
              <td><p>{val.menuTopLink}</p></td>
              <td><p>{val.menuTopOrder}</p></td>
              <td width="50px">
                <button>
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"></path>
                    </g>
                  </svg>
                </button>
              </td>
              <td width="50px">
                <button
                  onClick={() => {
                    removeMenuTop(val.id);
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
          })}
        </tbody>
      </table>

    </section>

  );

}