import React from "react";

import "./css/Reset.css";
import "./css/Scale.css";
import "./css/Load.css";
import "./views/private/css/1/NSappGlobal.css";
import "./font/Font.css";

import ScrollToTop from "./views/settings/ScrollToTop";

import NSlogin from "./views/middleware/NSaccessLogin";
import NSadminPanel from "./views/middleware/NSaccessDashboard";
import NSadminBlog from "./views/middleware/NSaccessBlog";
import NSadminMedia from "./views/middleware/NSaccessMedia";
import NSadminMessage from "./views/middleware/NSaccessMessage";
import NSadminSettings from "./views/middleware/NSaccessSettings";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export default function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>

          <Route
            path="/"
            exect
            element={<NSadminPanel />}
          />

          <Route
            path="/blog"
            element={<NSadminBlog />}
          />

          <Route
            path="/media"
            element={<NSadminMedia />}
          />

          <Route
            path="/message"
            element={<NSadminMessage />}
          />

          <Route
            path={"/settings"}
            element={<NSadminSettings />}
          />

          <Route
            path={"/login"}
            element={<NSlogin />}
          />

        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}