import React from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

import "./css/1/stat.css";

export default function Stat() {

  const data = {
    labels: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
    datasets: [
      {
        label: "+",
        data: [50, 30, 60, 70, 40, 80, 90, 92, 102, 109, 101, 122],
        fill: true,
        borderColor: "rgba(0, 0, 0, 0.01)",
        backgroundColor: "rgba(250, 218, 175, 0.2)",
        tension: 0.4,
      },
      {
        label: "-",
        data: [27, 23, 26, 27, 34, 28, 39, 40, 60, 40, 34, 28],
        fill: true,
        borderColor: "rgba(0, 0, 0, 0.01)",
        backgroundColor: "rgba(250, 218, 175, 0.2)",
        tension: 0.4,
      },
    ],
  };

  const options = {
    animations: {
      tension: {
        duration: 5000,
        easing: 'linear',
        from: 0.6,
        to: 0.2,
        loop: true
      }
    },
    plugins: {
      title: {
        display: true,
        text: "View",
        color: "rgba(250, 218, 175, 1)",
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "rgba(250, 218, 175, 1)"
        }
      },
    },
    scales: {
      x: {
        type: "category",
        beginAtZero: true,
        grid: {
          color: "rgba(250, 218, 175, 0)"
        },
        ticks: {
          color: "rgba(250, 218, 175, 1)"
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(250, 218, 175, 0)"
        },
        ticks: {
          color: "rgba(250, 218, 175, 1)"
        }
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    }
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <section className="Stat blurload">

      <h2>Stat</h2>
      <Line data={data} options={options} />

    </section >
  );

}