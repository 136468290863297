import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./css/1/NSuser.css";
import AOS from "aos";
import "aos/dist/aos.css";
import avatar from "../../img/avatar.webp";

export default function User() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  document.title = "User";

  const [active, setMode] = useState(false);
  const ToggleMode = () => {
    setMode(!active)
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View User Info //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userLoginReview, viewLoginUserReview] = useState([]);
  const [userLoginID, viewLoginUserID] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        viewLoginUserReview(response.data.user[0]);
        viewLoginUserID(response.data.user[0].userID);
      }
    })
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userReview, viewUserReview] = useState([]);

  const fetchUser = () => {
    const password = "Fckgwrhqq@";
    Axios.get(`${apiUrl}/api/getUsers?password=${password}`).then((response) => {
      viewUserReview(response.data);
    })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Value To Input From (SQL)
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const userUserNameRef = useRef(null);
  const userNameRef = useRef(null);
  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);

  useEffect(() => {
    if (userUserNameRef.current) {
      userUserNameRef.current.value = userLoginReview.userName;
    }
    if (userNameRef.current) {
      userNameRef.current.value = userLoginReview.name;
    }
    if (userEmailRef.current) {
      userEmailRef.current.value = userLoginReview.email;
    }
    if (userPasswordRef.current) {
      userPasswordRef.current.value = userLoginReview.password;
    }
  }, [userLoginReview.userName, userLoginReview.name, userLoginReview.email, userLoginReview.password]);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Edit User   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userUsername, setUserUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userDate] = useState(userLoginReview.date);
  const [userRole] = useState(userLoginReview.role);

  const insertUser = async (event) => {
    event.preventDefault();
    try {
      const data = {
        userUsername: userUsername === "" ? userLoginReview.userName : userUsername,
        userPassword: userPassword === "" ? userLoginReview.password : userPassword,
        userEmail: userEmail === "" ? userLoginReview.email : userEmail,
        userName: userName === "" ? userLoginReview.name : userName,
        userDate: userDate === "" ? userLoginReview.date : userDate,
        userRole: userRole === "" ? userLoginReview.role : userRole,
      };
      Axios.post(`${apiUrl}/api/updateUser/${userLoginID}`, data);
      alert("Użytkownik został zaktualizowany.");
      fetchUser();
    } catch (err) {
      console.error(err);
    }
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Logout   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const logout = () => {
    Axios.get(`${apiUrl}/api/logout`)
      .then((response) => {
        if (response.status === 200) {
          alert("Wylogowano użytkownika: " + userLoginReview.userName);
          window.location.reload(false);
        } else {
          console.error("Błąd podczas wylogowywania");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="NSuser">
      <div
        className="avatar"
        data-aos={"fade-down"}
        data-aos-duration={"800"}
        data-aos-offset={"100"}
        data-aos-delay={"100"}
      >
        <img
          className={active ? "miconon" : "miconon"}
          onClick={ToggleMode}
          src={avatar}
          alt="avatar"
          width="600px"
          height="600px"
        />
      </div>

      <h2>User ID: {userLoginReview.userID}</h2>

      <div className="iconBar">
        <Link key="back" to={process.env.PUBLIC_URL + "/"}>
          <button title="back">
            <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </g></svg>
          </button>
        </Link>
        <span>
          <button title="logout" className={active ? "miconon" : "miconon"} onClick={ToggleMode}>
            <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
              <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74 0-.2.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
            </g></svg>
          </button>
        </span>
        <span>
          <button title="logout" onClick={logout}>
            <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
              <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
            </g></svg>
          </button>
        </span>
      </div>

      <div
        className="userSettings"
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"100"}
        data-aos-delay={"100"}
      >
        {userReview
          .filter((val) => val.userID === parseInt(userLoginReview.userID))
          .map((val) => {
            return <div key="userInfo" className="NSform">
              <table className={active ? "close" : "open"}>
                <thead><tr><th>User Name</th></tr></thead>
                <tbody>
                  <tr key={val.userName}>
                    <td>{val.userName}</td>
                  </tr>
                </tbody>
                <thead><tr><th>Name</th></tr></thead>
                <tbody>
                  <tr key={val.name}>
                    <td>{val.name}</td>
                  </tr>
                </tbody>
                <thead><tr><th>E-mail</th></tr></thead>
                <tbody>
                  <tr key={val.email}>
                    <td>{val.email}</td>
                  </tr>
                </tbody>
                <thead><tr><th>Role</th></tr></thead>
                <tbody>
                  <tr key={val.role}>
                    <td>{val.role}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          })}

        <div className={active ? "open" : "close"}>
          <form className="NSform">
            <label htmlFor="username">User Name</label>
            <input
              id="username"
              type="text"
              name="username"
              className="username"
              ref={userUserNameRef}
              placeholder="Username"
              onChange={(e) => {
                setUserUsername(e.target.value)
              }} />
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              className="name"
              ref={userNameRef}
              placeholder="Name"
              onChange={(e) => {
                setUserName(e.target.value)
              }} />
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="text"
              name="email"
              className="email"
              ref={userEmailRef}
              placeholder="E-mail"
              onChange={(e) => {
                setUserEmail(e.target.value)
              }} />
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="text"
              name="password"
              className="password"
              ref={userPasswordRef}
              placeholder="Password"
              onChange={(e) => {
                setUserPassword(e.target.value)
              }} />
            <button
              onClick={insertUser}
            >
              Submit
            </button>
          </form>
        </div>

      </div>

    </section>

  );

}