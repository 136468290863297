import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/message.css";

export default function Message() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View Message List Date  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}:${month}:${year}`;
  };

  const [openIndex, setOpenIndex] = useState(null);

  const ToggleMode = (index) => {
    setOpenIndex((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Message //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removeMessage = async (messageid) => {
    Axios.delete(`${apiUrl}/api/deleteMessage/${messageid}`);
    alert("Wiadomość została usunięta.");
    fetchMessageList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Message  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [MessageReviewList, setMessageReviewList] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchMessageList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getMessageListCache`);
    return response.data;
  };

  const { data: messageReviewData, isLoading: isMessageReviewLoading } = useQuery(
    ["messageList"],
    fetchMessageList
  );

  useEffect(() => {
    if (messageReviewData) {
      setMessageReviewList(messageReviewData);
    }
  }, [messageReviewData]);

  useEffect(() => {
    fetchMessageList();
  }, []);

  if (isMessageReviewLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Message blurload">

      <h2>Message list:</h2>

      {MessageReviewList.map((val, index) => {
        const isOpen = openIndex === index;
        return (
          <div data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} key={val.id}>
            <table>
              <thead>
                <tr>
                  <th onClick={() => ToggleMode(index)}>
                    {formatDate(val.date)} : {val.subject}
                  </th>
                </tr>
              </thead>
              <tbody className={isOpen ? "open" : "close"}>
                <tr><td><p>ID : {val.id}</p></td></tr>
                <tr><td><p>Name : {val.name}</p></td></tr>
                <tr><td><p>Surname : {val.surname}</p></td></tr>
                <tr><td><p>E-mail : {val.email}</p></td></tr>
                <tr><td><p>Nr. Tel. : {val.nrtel}</p></td></tr>
                <tr><td><p>Subject : {val.subject}</p></td></tr>
                <tr><td><p>Message : {val.message}</p></td></tr>
              </tbody>
            </table>
            <button
              onClick={() => {
                removeMessage(val.id);
              }}
            >
              <svg viewBox="0 0 24 24">
                <g fill="none" className="nc-icon-wrapper">
                  <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                </g>
              </svg>
            </button>
          </div>
        );
      })}

    </section>

  );

}