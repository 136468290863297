import React, { useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "./css/1/NSlogin.css";

export default function NSlogin() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  document.title = "Login";

  const [active, setMode] = useState(false);
  const ToggleMode = () => {
    setMode(!active)
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  const Login = async () => {
    Axios.post(`${apiUrl}/api/login`, {
      username: Username,
      password: Password,
    }).then((response) => {
      if (response.data.message) {
        console.log(response);
        setLoginStatus(response.data.message);
      } else {
        setLoginStatus("Użytkownik " + response.data[0].userName + " został zalogowany.");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }
    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Register //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [UsernameReg, setUsernameReg] = useState("");
  const [PasswordReg, setPasswordReg] = useState("");
  const [EmailReg, setEmailReg] = useState("");
  const [NameReg, setNameReg] = useState("");
  const [DateReg, setDateReg] = useState("");
  const [RoleReg] = useState("user");
  const [registerStatus, setRegisterStatus] = useState("");

  const Register = async () => {
    Axios.post(`${apiUrl}/api/register`, {
      username: UsernameReg,
      password: PasswordReg,
      email: EmailReg,
      name: NameReg,
      date: DateReg,
      role: RoleReg,
    }).then((response) => {
      if (response.data.message) {
        console.log(response);
        setRegisterStatus(response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } else {
        setRegisterStatus(response.data.message);
      }
    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="NSlogin">

      <span className="switchs">
        <Link key="back" to={process.env.PUBLIC_URL + "/"}>
          <button className="switch" title="back" style={{ background: "transparent", width: "auto", aspectRatio: "2 / 1" }}>
            <svg viewBox="0 0 24 24"><g fill="none" className="nc-icon-wrapper">
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="#000"></path>
            </g></svg>
          </button>
        </Link>
        <button title="login" className={active ? "off switch" : "on switch"} onClick={ToggleMode}>Login</button>
        <button title="register" className={active ? "on switch" : "off switch"} onClick={ToggleMode}>Register</button>
      </span>

      <span className={active ? "close info" : "open info"}>
        {loginStatus}
      </span>

      <span className={active ? "open info" : "close info"}>
        {registerStatus}
      </span>

      <div className={active ? "close authorize" : "open authorize"}>

        <h2>Login</h2>

        <form className="form loginform" onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="login">Your login</label>
          <input
            id="login"
            type="text"
            name="username"
            placeholder="Login:"
            autoComplete="off"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <label htmlFor="password">Your password</label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Password:"
            autoComplete="off"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <span>
            <button title="login" onClick={Login}>Login</button>
          </span>
        </form>

      </div>

      <div className={active ? "open authorize" : "close authorize"}>

        <h2>Register</h2>

        <form className="form regform" onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="reglogin">Your login</label>
          <input
            id="reglogin"
            type="text"
            name="username"
            placeholder="Login:"
            autoComplete="off"
            onChange={(e) => {
              setUsernameReg(e.target.value);
            }}
          />
          <label htmlFor="regpassword">Your password</label>
          <input
            id="regpassword"
            type="password"
            name="password"
            placeholder="Password:"
            autoComplete="off"
            onChange={(e) => {
              setPasswordReg(e.target.value);
            }}
          />
          <label htmlFor="regemail">Your E-mail</label>
          <input
            id="regemail"
            type="email"
            name="email"
            placeholder="E-Mail:"
            onChange={(e) => {
              setEmailReg(e.target.value);
            }}
          />
          <label htmlFor="regname">Your Name or Nickname</label>
          <input
            id="regname"
            type="text"
            name="mame"
            placeholder="Name/Nickname:"
            onChange={(e) => {
              setNameReg(e.target.value);
            }}
          />
          <label htmlFor="regdate">Date your birth</label>
          <input
            id="regdate"
            type="date"
            name="date"
            onChange={(e) => {
              setDateReg(e.target.value);
            }}
          />
          <span>
            <button title="register" onClick={Register}>Register</button>
          </span>
        </form>

      </div>

    </section>

  );

}