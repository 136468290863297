import React, { useState, useEffect } from "react";
import Axios from "axios";

import Loading from "../settings/Loading";
import NSlogin from "../private/NSlogin";
import NSuser from "../private/NSuser";

export default function NSadmin() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  const [role, setRole] = useState("");

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        setRole(response.data.user[0].role);
      }
    });
  }, []);

  return (
    <section className="app NSadmin">
      <Loading />
      {role === "admin" && <NSuser />}
      {role === "user" && <NSuser />}
      {role === "" && <NSlogin />}
    </section>
  );

}