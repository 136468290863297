import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/blog.css";

// Editor quill imports
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Blog() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Quill Editor   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  Quill.modules = {

    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login Status   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userID, viewUserID] = useState("");

  useEffect(() => {
    // View Login //
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        viewUserID(response.data.user[0].userID);
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Post //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [postTitle, setPostTitle] = useState("");
  const [imageDataBaner, setImageDataBaner] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  const [postCategory, setPostCategory] = useState("");

  const submitPost = async (event) => {
    event.preventDefault();
    Axios.post(`${apiUrl}/api/insertPost`, {
      authorID: userID,
      postTitle: postTitle,
      imageDataBaner: imageDataBaner,
      editorHtml: editorHtml,
      postCategory: postCategory,
    });
    alert("Dodano artykół.");
    fetchBlogList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Post //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removePost = async (postid) => {
    Axios.delete(`${apiUrl}/api/deletePost/${postid}`);
    alert("Usunięto artykół.");
    fetchBlogList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Category - Blog - Media //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [CategoryReviewList, setCategoryReviewList] = useState([]);
  const [BlogReviewList, setBlogReviewList] = useState([]);
  const [MediaReview, setMediaReview] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchCategoryList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getCategoryList`);
    return response.data;
  };

  const fetchBlogList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getPostList`);
    return response.data;
  };

  const fetchMediaList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getImage`);
    return response.data;
  };

  const { data: categoryReviewListData, isLoading: isCategoryReviewListLoading } = useQuery(
    ["categoryList"],
    fetchCategoryList
  );

  const { data: blogReviewListData, isLoading: isBlogReviewListLoading } = useQuery(
    ["blogList"],
    fetchBlogList
  );

  const { data: mediaReviewData, isLoading: isMediaReviewLoading } = useQuery(
    ["mediaList"],
    fetchMediaList
  );

  useEffect(() => {
    if (categoryReviewListData) {
      setCategoryReviewList(categoryReviewListData);
    }
  }, [categoryReviewListData]);

  useEffect(() => {
    if (blogReviewListData) {
      setBlogReviewList(blogReviewListData);
    }
  }, [blogReviewListData]);

  useEffect(() => {
    if (mediaReviewData) {
      setMediaReview(mediaReviewData);
    }
  }, [mediaReviewData]);

  useEffect(() => {
    fetchBlogList();
  }, []);

  if (isCategoryReviewListLoading || isBlogReviewListLoading || isMediaReviewLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Blog blurload">

      <h2>Blog</h2>

      <input
        type="text"
        name="postTitle"
        placeholder="Post Title:"
        onChange={(e) => {
          setPostTitle(e.target.value)
        }} />

      <fieldset>
        {MediaReview
          .filter((val) => val.authorID === parseInt(userID) && val.mediaFor === "post")
          .map((val) => {
            return <input
              data-aos={"fade-left"}
              data-aos-duration={"800"}
              data-aos-offset={"50"}
              data-aos-delay={"400"}
              type="radio"
              name="baner"
              key={val.id}
              value={val.mediaName}
              style={{ backgroundImage: `url(${val.thumbnail})` }}
              onChange={(e) => { setImageDataBaner(e.target.value) }}
            />
          })}
      </fieldset>

      <ReactQuill
        theme="snow"
        value={editorHtml}
        onChange={setEditorHtml}
        modules={Quill.modules}
      />

      <select
        name="postCategory"
        onChange={(e) => { setPostCategory(e.target.value) }}>
        <option key="Category" value="blog">Chose category</option>
        {CategoryReviewList.map((val) => {
          return <option key={val.id} value={val.categoryName}>
            {val.categoryName}
          </option>
        })}
      </select>

      <button onClick={submitPost}>Submit</button>

      <table>
        <thead><tr><th width="15%">Thumb</th><th>Post title</th><th>Content</th><th>Category</th><th width="8%"></th><th width="8%"></th></tr></thead>
        <tbody>
          {BlogReviewList
            .filter((val) => val.authorID === parseInt(userID))
            .map((val) => {
              return <tr data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} className="fadeload" key={val.id}>
                <td width="15%" className="tdThumb"><img src={`${apiUrl}/uploads/images/thumbnail/${val.imageDataBaner}`} /></td>
                <td><p>{val.authorID} - {val.postTitle}</p></td>
                <td><p>
                  {val.editorHtml.length > 32
                    ? val.editorHtml.substring(0, 32) + "..."
                    : val.editorHtml}
                </p></td>
                <td><p>{val.postCategory}</p></td>
                <td width="8%"><button>
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06zM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"></path>
                    </g>
                  </svg>
                </button></td>
                <td width="8%"><button
                  onClick={() => {
                    removePost(val.id);
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <g fill="none" className="nc-icon-wrapper">
                      <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                    </g>
                  </svg>
                </button></td>
              </tr>
            })}
        </tbody>
      </table>

    </section>

  );

}

