import React from "react";

import "./css/1/info.css";

export default function Info() {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <section className="Info blurload">

      <span className="circle1">
        <span className="circle2">
          <span className="circle3">
            <h2>22 : 07</h2>
            <div className="fogbg">
              <div className="container">
                <div className="img img--first"></div>
                <div className="img img--second"></div>
              </div>
            </div>
          </span>
        </span>
      </span>

    </section >
  );

}