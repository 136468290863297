import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/media.css";

export default function Media() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Login Status   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userID, viewUserID] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        viewUserID(response.data.user[0].userID);
      }
    })
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // File Uploads   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [dataFile, setDataFile] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      alert("Wybrany plik nie jest obrazem!");
      return;
    }
    const dataFile = new FormData();
    dataFile.append("file", file);
    setDataFile(dataFile);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Add Media   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [mediaType] = useState("image");
  const [mediaFor, setMediaFor] = useState("");

  const submitMedia = async (event) => {
    event.preventDefault();
    const { data: { iFileName, iFileUrl, iFileOptimizedUrl, iFileThumbnailUrl } } = await Axios.post(`${apiUrl}/api/uploadImage`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    Axios.post(`${apiUrl}/api/insertMedia`, {
      authorID: userID,
      mediaType: mediaType,
      mediaFor: mediaFor,
      iFileName: iFileName,
      iFileUrl: iFileUrl,
      iFileOptimizedUrl: iFileOptimizedUrl,
      iFileThumbnailUrl: iFileThumbnailUrl,
    });
    alert("Obiekt graficzny został dodany.");
    fetchMediaList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Remove Media   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const removeMedia = async (mediaid, medianame) => {
    Axios.delete(`${apiUrl}/api/deleteMedia/${mediaid}`);
    Axios.delete(`${apiUrl}/api/deleteImage/${medianame}`);
    alert("Obiekt graficzny został usunięty.");
    fetchMediaList();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Media //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [MediaReview, setMediaReview] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const fetchMediaList = async () => {
    const response = await Axios.get(`${apiUrl}/api/getImage`);
    return response.data;
  };

  const { data: mediaReviewData, isLoading: isMediaReviewLoading } = useQuery(
    ["mediaList"],
    fetchMediaList
  );

  useEffect(() => {
    if (mediaReviewData) {
      setMediaReview(mediaReviewData);
    }
  }, [mediaReviewData]);

  useEffect(() => {
    fetchMediaList();
  }, []);

  if (isMediaReviewLoading) {
    // return <div></div>;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Media blurload">

      <h2>Media</h2>

      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
      />

      <select
        name="mediaFor"
        onChange={(e) => { setMediaFor(e.target.value) }}>
        <option key="Mediafor" value="all">Media for</option>
        <option key="Post" value="post">for Post</option>
        <option key="Category" value="category">for Category</option>
        <option key="Gallery" value="gallery">for Gallery</option>
      </select>

      <button onClick={submitMedia}>Submit</button>

      <div className="medialist">
        {MediaReview
          .filter((val) => val.authorID === parseInt(userID))
          .map((val) => {
            return <div data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} className="mediaitem fadeload" key={val.id}>
              <div className="thumb" style={{ backgroundImage: `url(${val.optimized})` }}>
              </div>
              <p>Media for: {val.mediaFor}</p>
              <button
                onClick={() => {
                  removeMedia(val.id, val.mediaName);
                }}
              >
                <svg viewBox="0 0 24 24">
                  <g fill="none" className="nc-icon-wrapper">
                    <path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
                  </g>
                </svg>
              </button>
            </div>
          })}
      </div>

    </section>

  );

}

