import React, { useState, useEffect } from "react";
import Axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

import "./css/1/userlist.css";

export default function Userlist() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Get User Info  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [userReviewList, viewUserList] = useState([]);

  const fetchUser = () => {
    const password = "Fckgwrhqq@";
    Axios.get(`${apiUrl}/api/getUsers?password=${password}`).then((response) => {
      viewUserList(response.data);
    })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Userlist blurload">

      <h2>User list</h2>

      <table>
        <thead><tr><th width="10%">ID</th><th width="20%">Username</th><th width="20%">Name</th><th width="35%">E-mail</th><th width="15%">Role</th></tr></thead>
        <tbody>
          {userReviewList.map((val) => {
            return <tr data-aos={"fade-left"} data-aos-duration={"800"} data-aos-offset={"50"} data-aos-delay={"400"} key={val.userID}>
              <td width="10%"><p>{val.userID}</p></td>
              <td width="20%"><p>{val.userName}</p></td>
              <td width="20%"><p>{val.name}</p></td>
              <td width="35%"><p>{val.email}</p></td>
              <td width="15%"><p>{val.role}</p></td>
            </tr>
          })}
        </tbody>
      </table>

    </section>

  );

}