import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";

import "./css/1/settings.css";

export default function Settings() {

  const apiUrl = "https://api.srv31894.seohost.com.pl:443";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Get Settings   //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [viewName, getName] = useState("");
  const [viewEmail, getEmail] = useState("");
  const [viewDate, getDate] = useState("");

  useEffect(() => {
    Axios.get(`${apiUrl}/api/session`).then((response) => {
      if (response.data.loggedIn === true) {
        getName("Name: " + response.data.user[0].name);
        getEmail("E-Mail: " + response.data.user[0].email);
        getDate("Birth Date: " + response.data.user[0].date);
      }
    });
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [viewAppName, getAppName] = useState("");
  const [viewAppBaseUrl, getAppBaseUrl] = useState("");
  const [viewAppTel, getAppTel] = useState("");
  const [viewAppDescription, getAppDescription] = useState("");

  const fetchSettings = () => {
    Axios.get(`${apiUrl}/api/getSettingsCache`).then((response) => {
      getAppName(response.data[0].appName);
      getAppBaseUrl(response.data[0].appBaseUrl);
      getAppTel(response.data[0].appTel);
      getAppDescription(response.data[0].appDescription);
    })
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Add Value To Input From (SQL)

  const appNameRef = useRef(null);
  const appBaseUrlRef = useRef(null);
  const appTelRef = useRef(null);
  const appDescriptionRef = useRef(null);

  useEffect(() => {
    if (appNameRef.current) {
      appNameRef.current.value = viewAppName;
    }
    if (appBaseUrlRef.current) {
      appBaseUrlRef.current.value = viewAppBaseUrl;
    }
    if (appTelRef.current) {
      appTelRef.current.value = viewAppTel;
    }
    if (appDescriptionRef.current) {
      appDescriptionRef.current.innerText = viewAppDescription;
    }
  }, [viewAppName, viewAppBaseUrl, viewAppTel, viewAppDescription]);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // Edit Settings  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  const [appName, setAppName] = useState("");
  const [appBaseUrl, setAppBaseUrl] = useState("");
  const [appTel, setAppTel] = useState("");
  const [appDescription, setAppDescription] = useState("");

  const editSettings = async (event) => {
    event.preventDefault();
    try {
      const data = {
        appName: appName === "" ? viewAppName : appName,
        appBaseUrl: appBaseUrl === "" ? viewAppBaseUrl : appBaseUrl,
        appTel: appTel === "" ? viewAppTel : appTel,
        appDescription: appDescription === "" ? viewAppDescription : appDescription,
      };
      Axios.post(`${apiUrl}/api/updateSettings`, data);
      alert("Ustawienia zostały zaktualizowane.");
      fetchSettings();
    } catch (err) {
      console.error(err);
    }
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (

    <section className="Settings blurload">

      <h2>Settings</h2>

      <label htmlFor="sitename">Site Name</label>
      <input
        id="sitename"
        type="text"
        name="appName"
        className="appName"
        ref={appNameRef}
        placeholder="App Name"
        onChange={(e) => {
          setAppName(e.target.value)
        }} />
      <label htmlFor="siteurl">Site Base URL</label>
      <input
        id="siteurl"
        type="text"
        name="appBaseUrl"
        className="appBaseUrl"
        ref={appBaseUrlRef}
        placeholder="Base App Url"
        onChange={(e) => {
          setAppBaseUrl(e.target.value)
        }} />
      <label htmlFor="nrtel">Phone Number</label>
      <input
        id="nrtel"
        type="number"
        name="appTel"
        className="appTel"
        ref={appTelRef}
        placeholder="Tel nr"
        onChange={(e) => {
          setAppTel(e.target.value)
        }} />
      <label htmlFor="description">Description</label>
      <textarea
        rows="10"
        name="appDescription"
        className="appDescription"
        ref={appDescriptionRef}
        placeholder="Description"
        onChange={(e) => {
          setAppDescription(e.target.value)
        }}></textarea>
      <button
        onClick={editSettings}
      >
        Submit
      </button>

      <p>{viewName}</p>
      <p>{viewEmail}</p>
      <p>{viewDate}</p>

    </section>

  );

}