import React, { useEffect } from "react";

import "./css/1/App.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Navigation from "./components/modules/navigation";
import Sidebar from "./components/modules/sidebar";
import Info from "./components/modules/info";
import Message from "./components/modules/message";

export default function NSadminMessage() {

  document.title = "Message";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="NSpanel">
      <div className="NS100g">
        <Navigation />
      </div>
      <div className="NS25g">
        <Sidebar />
      </div>
      <div className="NS50g">
        <Info />
      </div>
      <div className="NS25g">
        <Message />
      </div>
    </div >
  );

}
