import React, { useEffect } from "react";

import "./css/1/App.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Navigation from "./components/modules/navigation";
import Sidebar from "./components/modules/sidebar";
import Info from "./components/modules/info";
import Media from "./components/modules/media";

export default function NSadminMedia() {

  document.title = "Media";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // View  //-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  return (
    <div className="NSpanel">
      <div className="NS100g">
        <Navigation />
      </div>
      <div className="NS25g">
        <Sidebar />
      </div>
      <div className="NS50g">
        <Info />
      </div>
      <div className="NS25g">

      </div>
      <div
        className="NS100g"
        data-aos={"fade-up"}
        data-aos-duration={"800"}
        data-aos-offset={"100"}
        data-aos-delay={"100"}
      >
        <Media />
      </div>
    </div >
  );

}
